import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import React from 'react';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { GroupSubscriptionModel } from '../../../../../domain/model/GroupSubscriptionModel';
import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import {
  StripeInvoiceLineItemModel
} from '../../../../../domain/model/stripe/StripeInvoiceLineItemModel';
import { StripeInvoiceModel } from '../../../../../domain/model/stripe/StripeInvoiceModel';
import {
  StripePaymentMethodModel
} from '../../../../../domain/model/stripe/StripePaymentMethodModel';
import { StripeVoucherModel } from '../../../../../domain/model/stripe/StripeVoucherModel';
import { StripeProxy } from '../../../../../domain/proxy/StripeProxy';
import { UserProxy } from '../../../../../domain/proxy/UserProxy';
import { I18nService } from '../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { WsService } from '../../../../../domain/service/WsService';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { Types } from '../../../../../inversify/types';
import { appRoutes } from '../../../../../router/routes';
import {
  STRIPE_INVOICE_LINE_ITEM
} from '../../../../../shared/enum/stripe/stripeInvoiceLineItemType.enum';
import {
  STRIPE_SUBSCRIPTION_STATUS
} from '../../../../../shared/enum/stripe/stripeSubscriptionStatus.enum';
import { STRIPE_VOUCHER_STATUS } from '../../../../../shared/enum/stripe/stripeVoucherStatus.enum';
import { WsEvent } from '../../../../../shared/ws/ws.event';
import { currencySymbol } from '../../../../../util/CurrencyHelper';
import { ManageGroupRouteVm } from '../ManageGroupRouteVm';
import { AddMemberModalRef } from './add-member-modal/AddMemberModal';

@transient()
export class AddMemberRouteVm extends ViewModel {

  public addMemberModalRef: React.RefObject<AddMemberModalRef> = React.createRef();

  @observable
  public relatedMembers: GroupSubscriptionUserModel[] = [];

  @observable
  public selectedUsers: Set<GroupSubscriptionUserModel> = new Set();

  /** This is for a CURRENT amount in SummaryTable, initial call to backend so we have current state.  */
  @observable
  public currentInvoiceLineItems: StripeInvoiceLineItemModel[] = [];

  /** This is for a TOTAL NEW amount in SummaryTable. */
  @observable
  public upcomingInvoiceLineItems: StripeInvoiceLineItemModel[] = [];

  /** This is for a NEXT invoice amount. Calculation is done by Stripe. */
  @observable
  public upcomingInvoice: StripeInvoiceModel = new StripeInvoiceModel();

  @observable
  public paymentMethod: StripePaymentMethodModel | null = null;

  @observable
  public vouchers: StripeVoucherModel[] = [];

  @observable
  public groupSubscriptionModel: GroupSubscriptionModel | null = null;

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(SessionStore) public readonly sessionStore: SessionStore,
    @inject(ManageGroupRouteVm) public readonly manageGroupVm: ManageGroupRouteVm,
    @inject(StripeProxy) private readonly stripeProxy: StripeProxy,
    @inject(UserProxy) private readonly userProxy: UserProxy,
    @inject(WsService) private readonly wsService: WsService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    await this.fetchData.run();

    this.wsService.registerHandler(WsEvent.UserStripeChargeSucceeded, this.rerouteUser);
    this.wsService.registerHandler(WsEvent.UserStripeChargePending, this.rerouteUser);
  }

  public override onDestroy = () => {
    this.wsService.deregisterHandler(WsEvent.UserStripeChargeSucceeded, this.updateGroupSubscription.run);
    this.wsService.deregisterHandler(WsEvent.UserStripeChargePending, this.updateGroupSubscription.run);
  }

  // * When upgrading subscription is successful or SEPA payment is pending, re-route user to manage group screen
  private rerouteUser = () => setTimeout(
    () => {
      this.navigate(appRoutes.manageGroup);
    }
    , 3000);

  public fetchData = new AsyncTask(async () => {
    await this.getVouchers.run();

    if (this.unredeemedVouchers.length === 0) {
      await Promise.all([
        this.manageGroupVm.getSubscriptionInfo.run(),
        this.getRelatedMembers.run(),
        this.getUpcomingInvoiceLineItems.run(),
        this.getUpcomingInvoice.run(),
        this.getPaymentMethods.run(),
      ]);
    } else {
      await Promise.all([
        this.manageGroupVm.getSubscriptionInfo.run(),
        this.getRelatedMembers.run(),
      ]);
    }
  })

  @computed
  public get membersAndExtendedSubscriptionInfo(): string {
    return `${this.i18n.t('manage_group:add_members.subscription_summary_info_3', { count: this.selectedUsers.size, price: this.upcomingInvoicePartialAmount })}`;
  }

  @computed
  public get oldQuantity(): number {
    return this.manageGroupVm.groupSubscriptionInfo.quantity;
  }

  @computed
  public get newQuantity(): number {
    return this.manageGroupVm.groupSubscriptionInfo.quantity + this.selectedUsers.size;
  }

  /** Current price */
  @computed
  public get oldTotal(): string {
    const oldAmountTotal: number = this.currentInvoiceLineItems.reduce((sum, item) => sum + item.amount, 0);
    return `${oldAmountTotal.toFixed(2)} ${this.getCurrencyAndIntervalValue()}`;
  }

  /** New price */
  @computed
  public get newTotal(): string {
    const newAmountTotal: number = this.upcomingInvoiceLineItems.reduce((sum, item) => sum + item.amount, 0);
    return `${newAmountTotal.toFixed(2)} ${this.getCurrencyAndIntervalValue()}`;
  }

  @computed
  public get taxRate(): string {
    return `${this.upcomingInvoice.taxRate}%`;
  }

  /** Total price in current year */
  @computed
  public get upcomingInvoicePartialAmount(): string {
    return this.upcomingInvoice.amount + currencySymbol(this.upcomingInvoice.currency);
  }

  /** Next billing amount */
  @computed
  public get upcomingInvoiceFullAmount(): string {
    const totalAmount: number = this.upcomingInvoiceLineItems.reduce((sum, item) => sum + item.amount, 0);
    return totalAmount.toFixed(2) + currencySymbol(this.upcomingInvoice.currency);
  }

  @computed
  public get existingAndSelectedMembersIds(): string[] {
    const allExistingParticipantsOfGroup = this.manageGroupVm.allParticipantsOfGroup.map(participant => participant.id);
    const newlyAddedParticipantsOfGroup = Array.from(this.selectedUsers, selectedUser => selectedUser.id);
    return [...allExistingParticipantsOfGroup, ...newlyAddedParticipantsOfGroup];
  }

  @computed
  public get unredeemedVouchers() {
    return this.vouchers.filter(voucher => voucher.status === STRIPE_VOUCHER_STATUS.ACTIVE);
  }

  @computed
  public get shouldRenderForwardingBlockerModal() {
    const { status } = this.groupSubscriptionModel || {};
    return status &&
      status !== STRIPE_SUBSCRIPTION_STATUS.ACTIVE &&
      status !== STRIPE_SUBSCRIPTION_STATUS.TRIALING &&
      status !== STRIPE_SUBSCRIPTION_STATUS.CANCELED &&
      status !== STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED;
  }

  @action
  public setVouchers = (vouchers: StripeVoucherModel[]) => {
    this.vouchers = vouchers;
  }

  @action
  public setRelatedMembers = (relatedMembers: GroupSubscriptionUserModel[]) => {
    this.relatedMembers = relatedMembers.filter(member => member.id !== this.sessionStore.userId);
  }

  @action
  public setSelectedUsers = (selectedUsers: Set<GroupSubscriptionUserModel>) => {
    this.selectedUsers = selectedUsers;
  }

  @action
  public setCurrentInvoiceLineItems = (currentInvoiceLineItems: StripeInvoiceLineItemModel[]) => {
    this.currentInvoiceLineItems = currentInvoiceLineItems;
  }

  @action
  public setUpcomingInvoiceLineItems = (upcomingInvoiceLineItems: StripeInvoiceLineItemModel[]) => {
    this.upcomingInvoiceLineItems = upcomingInvoiceLineItems;
  }

  @action
  public setUpcomingInvoice = (upcomingInvoice: StripeInvoiceModel) => {
    this.upcomingInvoice = upcomingInvoice;
  }

  @action
  public setPaymentMethod = (paymentMethod: StripePaymentMethodModel | null) => {
    this.paymentMethod = paymentMethod;
  }

  @action
  private setGroupSubscriptionModel = (groupSubscriptionModel: GroupSubscriptionModel | null) => {
    this.groupSubscriptionModel = groupSubscriptionModel;
  }

  public handleCheckboxChange = (checked: boolean, relatedMember: GroupSubscriptionUserModel) => {
    const updatedSelectedUsers = new Set(this.selectedUsers);

    if (checked) {
      updatedSelectedUsers.add(relatedMember);
    } else {
      updatedSelectedUsers.delete(relatedMember);
    }

    this.setSelectedUsers(updatedSelectedUsers);
  }

  private getRelatedMembers = new AsyncTask(async () => {
    try {
      const response = await this.stripeProxy.getRelatedMembers();

      if (response.ok) {
        return this.setRelatedMembers(response.data);
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_related_members'));
    } catch (error) {
      console.error(`exception while fetching group subscription info. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_related_members'));
    }
  });

  public getVouchers = new AsyncTask(async () => {
    try {
      const result = await this.userProxy.getUserVouchers.run();

      if (result.ok) {
        return this.setVouchers(result.data);
      }
      this.notification.error(this.i18n.t('profile:vouchers.error'));
    } catch (e) {
      console.error(`error while fetching vouchers. ${e}`);
      this.notification.error(this.i18n.t('profile:vouchers.error'));
    }
  })

  public getUpcomingInvoiceLineItems = new AsyncTask(async (quantity?: number | undefined) => {
    try {
      const response = await this.stripeProxy.getUpcomingInvoiceLines(quantity);

      if (response.ok) {
        const invoiceLineItems = response.data.filter(item => item.type === STRIPE_INVOICE_LINE_ITEM.SUBSCRIPTION);

        return quantity === undefined
          ? this.setCurrentInvoiceLineItems(invoiceLineItems ?? response.data)
          : this.setUpcomingInvoiceLineItems(invoiceLineItems ?? response.data);
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_upcoming_invoice_line_items'));

    } catch (error) {
      console.error(`exception while fetching group subscription info. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_upcoming_invoice_line_items'));
    }
  });

  public getUpcomingInvoice = new AsyncTask(async (quantity?: number | undefined) => {
    try {
      const response = await this.stripeProxy.getUpcomingInvoice(quantity);

      if (response.ok) {
        return this.setUpcomingInvoice(response.data);
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_upcoming_invoice'));

    } catch (error) {
      console.error(`exception while fetching group subscription info. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_upcoming_invoice'));
    }
  });

  public getPaymentMethods = new AsyncTask(async () => {
    try {
      const response = await this.stripeProxy.getPaymentMethods();

      if (response.ok) {
        // * Always use [0] because we will only have one payment method in the array.
        return this.setPaymentMethod(response.data.length ? response.data[0] : null);
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_payment_methods'));

    } catch (error) {
      console.error(`exception while fetching payment methods. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_payment_methods'));
    }
  });

  public updateGroupSubscription = new AsyncTask(async () => {
    try {
      const dto = this.manageGroupVm.groupSubscriptionInfo.toDto(
        this.existingAndSelectedMembersIds,
        this.upcomingInvoice.coupon
      );

      const response = await this.stripeProxy.updateGroupSubscription(dto);

      if (response.ok) {
        this.setGroupSubscriptionModel(response.data);
        await this.delayNavigation();
      } else {
        this.notification.error(this.i18n.t('manage_group:error.updating_group_subscription'));
      }
    } catch (error) {
      console.error(`Exception while updating group subscription: ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.updating_group_subscription'));
    }
  });

  private delayNavigation = () => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        if (this.groupSubscriptionModel?.paymentResolveUrl && this.shouldRenderForwardingBlockerModal) {
          return;
        } else {
          this.notification.success(this.i18n.t('manage_group:success.updating_group_subscription'));
          this.navigate(appRoutes.manageGroup);
        }
        resolve();
      }, 3000);
    });
  };


  private getCurrencyAndIntervalValue = () => {
    const currency = currencySymbol(this.upcomingInvoice.currency);
    const recurringInterval = this.i18n.t('manage_group:add_members.modal.recurring_interval_year');
    return `${currency}/${recurringInterval}`;
  }

}
